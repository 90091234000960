const { Button } = require("antd")
const { apiUserManagement } = require("api")
const { useState, useEffect } = require("react")
const { useTranslation } = require("react-i18next")

export default function BoxPricing({ setMenuOption }) {
  const { t } = useTranslation()
  const [show, setShow] = useState(false)

  function onCancel() {
    localStorage.setItem("pricing", 1)
    setShow(false)
  }

  useEffect(() => {
    async function getProfile() {
      try {
        const { data } = await apiUserManagement.getProfile()
        // data.adding_option.is_custom_role = true
        setMenuOption(data?.adding_option)
        if (data?.is_reach_limit) {
          if (localStorage.getItem("pricing") !== "1") {
            setShow(true)
          }
        }
      } catch (error) {
        console.log({ error })
      }
    }
    getProfile()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div>
      {show && (
        <div className="bg-white shadow-lg p-4 rounded">
          <div className="text-dark-500">
            <div className="flex gap-2 mb-4">
              <div className="text-sm mb-2">
                {t("You've used all the resources available in your current Package")}
              </div>
              <div>{icUp}</div>
            </div>
            <div className="mb-3">
              <Button size="small" type="primary" className="w-full">
                <a href="https://edoglobe.jp/contact" target="__blank">
                  {t("Upgrade Now")}
                </a>
              </Button>
            </div>
            <div>
              <Button size="small" onClick={onCancel} className="w-full">
                {t("Remind Me Later")}
              </Button>
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

const icUp = (
  <svg width="60" height="60" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M28.7306 11.0547L38.8011 25.154H33.9634V40.8461H36.0879V51.2097L28.7306 55.8948L21.3732 51.2096V40.8461H23.4978V25.154H18.6602L28.7306 11.0547Z"
      fill="#4DABD1"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M14.3298 24.2969L20.2114 32.5314H17.386V41.2874H19.2536V49.86L11.2738 44.7782V32.5314H8.44824L14.3298 24.2969Z"
      fill="#E3F1F8"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M20.4421 35.373L26.3235 43.6076H23.4981V52.5628L17.3859 48.6705V43.6076H14.5605L20.4421 35.373Z"
      fill="#BFE1EE"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M43.1325 24.2969L37.251 32.5314H40.0764V41.2874H38.2087V49.86L46.1886 44.7782V32.5314H49.014L43.1325 24.2969Z"
      fill="#E3F1F8"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M37.0201 35.373L31.1387 43.6076H33.9641V52.5628L40.0763 48.6705V43.6076H42.9017L37.0201 35.373Z"
      fill="#BFE1EE"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M15.7756 5.38086H19.6713V9.01278H23.3032V12.9085H19.6713V16.5405H15.7756V12.9085H12.1436V9.01278H15.7756V5.38086Z"
      fill="#4DABD1"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M33.9006 4.90032C33.9006 4.46028 34.2573 4.10352 34.6974 4.10352C35.1374 4.10352 35.4942 4.46028 35.4942 4.90032V7.37448C35.4942 7.81452 35.1374 8.17128 34.6974 8.17128C34.2573 8.17128 33.9006 7.81452 33.9006 7.37448V4.90032ZM50.4411 20.0868C50.4411 19.6468 50.7979 19.29 51.2379 19.29C51.678 19.29 52.0347 19.6468 52.0347 20.0868V22.561C52.0347 23.001 51.678 23.3578 51.2379 23.3578C50.7979 23.3578 50.4411 23.001 50.4411 22.561V20.0868ZM50.4411 25.971C50.4411 25.531 50.7979 25.1742 51.2379 25.1742C51.678 25.1742 52.0347 25.531 52.0347 25.971V28.4452C52.0347 28.8852 51.678 29.242 51.2379 29.242C50.7979 29.242 50.4411 28.8852 50.4411 28.4452V25.971ZM7.96484 14.7282C7.96484 14.2882 8.3216 13.9314 8.76164 13.9314C9.20168 13.9314 9.55844 14.2882 9.55844 14.7282V17.2024C9.55844 17.6424 9.20168 17.9992 8.76164 17.9992C8.3216 17.9992 7.96484 17.6424 7.96484 17.2024V14.7282ZM7.96484 20.6125C7.96484 20.1725 8.3216 19.8157 8.76164 19.8157C9.20168 19.8157 9.55844 20.1725 9.55844 20.6125V23.0867C9.55844 23.5267 9.20168 23.8835 8.76164 23.8835C8.3216 23.8835 7.96484 23.5267 7.96484 23.0867V20.6125ZM33.9006 10.7846C33.9006 10.3446 34.2573 9.98784 34.6974 9.98784C35.1374 9.98784 35.4942 10.3446 35.4942 10.7846V13.2588C35.4942 13.6988 35.1374 14.0556 34.6974 14.0556C34.2573 14.0556 33.9006 13.6988 33.9006 13.2588V10.7846Z"
      fill="#BFE1EE"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M42.7072 8.08789H46.6029V11.7198H50.2348V15.6155H46.6029V19.2474H42.7072V15.6155H39.0752V11.7198H42.7072V8.08789Z"
      fill="#4DABD1"
    />
  </svg>
)
